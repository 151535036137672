// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:35:48+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            b6efdb6a55dc7433ddf1ccb8f50526e5
//    Signature:      eyJpdiI6IlJpRDIxTTFDNTZNQXFMelY0dHlMRkE9PSIsInZhbHVlIjoiaGlQOTNvNTVwR0dvcTZqcVJXUkdld3FCNmh5Yit5ZXRXc0ZITmVkTEJSeWdHdHA4NHQybWZaMEZuSHhsVktSTklmeFJZWlhhcDJTcmZqNzZIQ1Zzd2FteHI4dVdtVXdLeXUwQWVsUk9QMVZhWm9BWXk5cWQ0YlkwRklnVkVZbzFaNlp6em5VYnA4dWVRZUFlc0I5SUF2bVlBSkpVd1QxZUcrXC9OUWFJSEdhcnNzWmJVOUJyN3pvaGFuTjRsaGErQzUwbkxRT1M5QjB4akpKa2d5R3lsWk9FOTVDMVdXSnZwNEpxRVp6WmgxaUpuSDZ0bE8rSThRYVhFaUliN1VuQ095S2JsRmNTbFJcL1BsOHU4cVRXVmxGN0VVZlpidkRxZllYSHFHS2UyUmtHUFgrTEFidUtyRmU1YjF3TUd0RVRsT3V2bXRUdVRUSnY5dTYyXC8zK2o4elFQMVlHQVpoRW5lRzVuR0Z5aVhsdlJnaStwcE1ocHMxTnZqUmhQbHpMcnNaWGY3cVpqclpia2c3RjNsekZNWFllQT09IiwibWFjIjoiOTgyYTJmMDViNzIxNzI2NzUwNzg0MWI1ZmM0OTM3NDQ3OTI3OWViN2U1NTUxOTJhYWMwNzAwMjBjMTE5MGY5YiJ9
if (!customElements.get('pickup-availability')) {
  customElements.define('pickup-availability', class PickupAvailability extends HTMLElement {
    constructor() {
      super();

      if(!this.hasAttribute('available')) return;

      this.errorHtml = this.querySelector('template').content.firstElementChild.cloneNode(true);
      this.onClickRefreshList = this.onClickRefreshList.bind(this);
      this.fetchAvailability(this.dataset.variantId);
    }

    fetchAvailability(variantId) {
      let rootUrl = this.dataset.rootUrl;
      if (!rootUrl.endsWith("/")) {
        rootUrl = rootUrl + "/";
      }
      const variantSectionUrl = `${rootUrl}variants/${variantId}/?section_id=pickup-availability`;

      fetch(variantSectionUrl)
        .then(response => response.text())
        .then(text => {
          const sectionInnerHTML = new DOMParser()
            .parseFromString(text, 'text/html')
            .querySelector('.shopify-section');
          this.renderPreview(sectionInnerHTML);
        })
        .catch(e => {
          const button = this.querySelector('button');
          if (button) button.removeEventListener('click', this.onClickRefreshList);
          this.renderError();
        });
    }

    onClickRefreshList(evt) {
      this.fetchAvailability(this.dataset.variantId);
    }

    renderError() {
      this.innerHTML = '';
      this.appendChild(this.errorHtml);

      this.querySelector('button').addEventListener('click', this.onClickRefreshList);
    }

    renderPreview(sectionInnerHTML) {
      const drawer = document.querySelector('pickup-availability-drawer');
      if (drawer) drawer.remove();
      if (!sectionInnerHTML.querySelector('pickup-availability-preview')) {
        this.innerHTML = "";
        this.removeAttribute('available');
        return;
      }

      this.innerHTML = sectionInnerHTML.querySelector('pickup-availability-preview').outerHTML;
      this.setAttribute('available', '');

      document.body.appendChild(sectionInnerHTML.querySelector('pickup-availability-drawer'));

      const button = this.querySelector('button');
      if (button) button.addEventListener('click', (evt) => {
        document.querySelector('pickup-availability-drawer').show(evt.target);
      });
    }
  });
}

if (!customElements.get('pickup-availability-drawer')) {
  customElements.define('pickup-availability-drawer', class PickupAvailabilityDrawer extends HTMLElement {
    constructor() {
      super();

      this.onBodyClick = this.handleBodyClick.bind(this);

      this.querySelector('button').addEventListener('click', () => {
        this.hide();
      });

      this.addEventListener('keyup', () => {
        if(event.code.toUpperCase() === 'ESCAPE') this.hide();
      });
    }

    handleBodyClick(evt) {
      const target = evt.target;
      if (target != this && !target.closest('pickup-availability-drawer') && target.id != 'ShowPickupAvailabilityDrawer') {
        this.hide();
      }
    }

    hide() {
      this.removeAttribute('open');
      document.body.removeEventListener('click', this.onBodyClick);
      document.body.classList.remove('overflow-hidden');
      removeTrapFocus(this.focusElement);
    }

    show(focusElement) {
      this.focusElement = focusElement;
      this.setAttribute('open', '');
      document.body.addEventListener('click', this.onBodyClick);
      document.body.classList.add('overflow-hidden');
      trapFocus(this);
    }
  });
}
